<template>
  <v-container>
    <Bg_tasks_list/>
  </v-container>
</template>

<script>
import Bg_tasks_list from "@/components/bg_tasks_sub_component/bg_tasks_list";

export default {
  name: "BgTasks",
  components: {Bg_tasks_list}
}
</script>

<style scoped>

</style>